import React from 'react';
import keyboard from '../../../static/images/gifs/keyboard.gif'
import increase from '../../../static/images/gifs/increase.gif'
import decrease from '../../../static/images/gifs/decrease.gif'
import gistogram from '../../../static/images/gifs/gistogram.gif'
import './EmptyScreen.scss'
const EmptyScreen = () => {
    return (
        <div className="empty-screen">
            <div className="empty-text">
                Скоро здесь будет красивая инфографика...
                <p>
                    <img src={increase} alt='waiting' className='textgif' />
                    <img src={decrease} alt='waiting' className='textgif' />
                    <img src={gistogram} alt='waiting' className='textgif' />
                </p>
            </div>
            <div className="empty-text">
                А пока мы трудимся
                <p>
                    <img src={keyboard} alt='working' className="keyboardLogo" />
                </p>
            </div>

        </div>
    );
};

export default EmptyScreen;