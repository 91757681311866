import React, { useRef, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import CustomSelect from '../CustomSelect/CustomSelect';
import Input from '../Input/Input';
import styles from './ProductManagementTable.module.scss';

import managementStore from '../../../stores/managementStore';
import categoriesStore from '../../../stores/categoriesStore';

const ProductManagementTable = observer(() => {
  const tableContainerRef = useRef(null);

  // Мемоизируем категории
  const categorySuggestions = useMemo(
    () => categoriesStore.categories.map((cat) => cat.name),
    [categoriesStore.categories]
  );

  // Мемоизируем данные для каждого поля
  const filterSuggestions = useMemo(() => {
    const suggestions = {};
    Object.keys(managementStore.filterValues).forEach((field) => {
      suggestions[field] = Array.from(managementStore.filterValues[field] || []);
    });
    return suggestions;
  }, [managementStore.filterValues]);

  const handleRowClick = (product) => {
    managementStore.setSelectedProduct(product);
  };

  const renderHeader = (column) => {
    if (['id', 'article', 'amount', 'price', 'goodCard', 'name'].includes(column.value)) {
      const isSorted = managementStore.sortField === column.value;
      const sortIcon = isSorted ? (managementStore.sortOrder === 'asc' ? '↑' : '↓') : '↕';
      return (
        <span
          className={styles.sortableHeader}
          onClick={() => managementStore.sortProducts(column.value)}
        >
          <div>{column.name}</div><div>{sortIcon}</div>
        </span>
      );
    }
    return (
      <CustomSelect
        options={Array.from(managementStore.filterValues[column.value] || []).map((val) => ({
          value: val,
          label: val,
        }))}
        selectedValues={Array.from(managementStore.activeFilters[column.value] || [])}
        onChange={(selectedValues) =>
          managementStore.setFilter(column.value, selectedValues)
        }
        mode="multi"
        placeholder={column.name}
      />
    );
  };

  const renderField = (product, field) => {
    const value = product[field];

    if (['color', 'size', 'categoryName', 'producerName', 'goodCard'].includes(field)) {
      const suggestions =
        field === 'categoryName' ? categorySuggestions : filterSuggestions[field];

      return (
        <Input
          value={value || ''}
          onChange={(newValue) =>
            managementStore.updateProductField(product.id, field, newValue)
          }
          /*onBlur={() =>
            managementStore.saveField(product.id, field, value)
          }*/
          suggestions={suggestions}
        />
      );
    }
    if (['name', 'price', 'amount'].includes(field)) {
      return (
        <Input
          value={value || ''}
          onChange={(newValue) =>
            managementStore.updateProductField(product.id, field, newValue)
          }
        /*onBlur={() =>
          managementStore.saveField(product.id, field, value)
        }*/
        />
      );
    }

    return <span>{value}</span>;
  };

  if (!managementStore.visibleColumns || managementStore.visibleColumns.length === 0) {
    return <div className={styles.noData}>Нет доступных данных для отображения</div>;
  }

  return (
    <div className={styles.tableContainer} ref={tableContainerRef}>
      <table>
        <colgroup>
          {managementStore.visibleColumns
            .filter((col) => col.visible)
            .map((column) => (
              <col
                key={column.value}
                className={styles[column.value]}
              />
            ))}
        </colgroup>
        <thead>
          <tr>
            {managementStore.visibleColumns
              .filter((col) => col.visible)
              .map((column) => (
                <th key={column.value} className={styles[column.value]}>
                  {renderHeader(column)}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {managementStore.paginatedProducts.map((product) => (
            <tr key={product.id}
              onClick={() => handleRowClick(product)}
              className={managementStore.selectedProduct?.id === product.id ? styles.selectedRow : ''}
            >
              {managementStore.visibleColumns
                .filter((col) => col.visible)
                .map((column) => (
                  <td key={column.value} className={styles[column.value]}>
                    {renderField(product, column.value)}
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default ProductManagementTable