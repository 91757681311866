import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import userStore from '../../../stores/userStore';
import { useNavigate } from 'react-router-dom';
import './Login.scss';

const Login = observer(({ showCreateCredentials = true }) => {
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [login, setLogin] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      await userStore.authenticateWithInitData(login, password);
    } catch (error) {
      console.error('Login failed', error);
      setError('Ошибка при входе');
    }
  };

  useEffect(() => {
    if (userStore.isAuthenticated) {
      navigate('/dashboard', { replace: true });
    }
  }, [userStore.isAuthenticated, navigate]);

  return (
    <div className='login-container'>
      <h2 className='header'>Вход</h2>
      {error && <p className="error">{error}</p>}
      {showCreateCredentials && (
        <input
          type="text"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          placeholder="Логин"
          className="input"
        />
      )}
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Пароль"
        className="input"
      />
      <button onClick={handleSubmit} className="button">
        Войти
      </button>
    </div>
  );
});

export default Login;