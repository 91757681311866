import userStore from "../stores/userStore";
import config from "./config";

export const getProductsEndpoint = () => {
    const role = userStore.role;
    let endpoint = '/products';
    let params = {};

    switch (role) {
        case 'Admin':
            endpoint = '/admin/products';
            break;
        case 'Manager':
            endpoint = '/manager/products';
            params.scope = config.Manager.scope;
            break;
        // Добавьте другие роли и соответствующие маршруты и параметры
        default:
            endpoint = '/products';
    }

    return { endpoint, params };
};
export const getCategoriesEndpoint = () => {
    const role = userStore.role;
    let endpoint = '/categories';
    let params = {};

    switch (role) {
        case 'Admin':
            endpoint = '/admin/categories';
            break;
        case 'Manager':
            endpoint = '/manager/categories';
            params.scope = config.Manager.scope;
            break;
        // Добавьте другие роли и соответствующие маршруты и параметры
        default:
            endpoint = '/categories';
    }

    return { endpoint, params };
};