import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import managementStore from '../../../stores/managementStore';
import styles from './ProductAdditionalInfo.module.scss';

const ProductAdditionalInfo = observer(() => {
    const product = managementStore.selectedProduct;
    const [description, setDescription] = useState('');
    const [mainPhotoIndex, setMainPhotoIndex] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);

    useEffect(() => {
        setDescription(product?.description || '');
        setMainPhotoIndex(0);
    }, [product]);

    if (!product) {
        return <div className={styles.placeholder}>Выберите продукт для просмотра информации</div>;
    }

    const handleDescriptionChange = (e) => {
        const newDescription = e.target.value;
        setDescription(newDescription);
        managementStore.updateProductField(product.id, 'description', newDescription);
    };

    const handleDeleteClick = async () => {
        if (window.confirm('Вы уверены, что хотите удалить этот продукт?')) {
            await managementStore.deleteProduct(product.id);
            managementStore.clearSelectedProduct();
        }
    };
    const handleReviveClick = async () => {
        await managementStore.reviveProduct(product.id);
    };

    const handlePreviousPhoto = () => {
        setMainPhotoIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : product.photoUrls.length - 1
        );
    };

    const handleNextPhoto = () => {
        setMainPhotoIndex((prevIndex) =>
            prevIndex < product.photoUrls.length - 1 ? prevIndex + 1 : 0
        );
    };

    const handleFullscreenToggle = () => {
        setIsFullscreen((prevState) => !prevState);
    };


    return (
        <div className={styles.additionalInfo}>
            <div className={styles.photos}>
                {product.photoUrls?.length > 0 ? (
                    <div className={styles.photoSection}>
                        {/* Главное фото */}
                        <div className={styles.mainPhotoContainer}>
                            <button className={styles.arrow} onClick={handlePreviousPhoto}>
                                &lt;
                            </button>
                            <img
                                src={product.photoUrls[mainPhotoIndex]}
                                alt={`Главное фото`}
                                className={styles.mainPhoto}
                                onClick={handleFullscreenToggle}
                            />
                            <button className={styles.arrow} onClick={handleNextPhoto}>
                                &gt;
                            </button>
                        </div>
                        {/* Галерея под главным фото */}
                        <div className={styles.photoGallery}>
                            {product.photoUrls.map((photoUrl, index) => (
                                <img
                                    key={index}
                                    src={photoUrl}
                                    alt={`Фото ${index + 1}`}
                                    className={styles.galleryPhoto}
                                    onClick={() => setMainPhotoIndex(index)}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <p>Нет фотографий</p>
                )}
            </div>
            <div className={styles.info}>
                <textarea
                    value={description}
                    onChange={handleDescriptionChange}
                /*onBlur={() =>
                    managementStore.saveField(product.id, 'description', description)
                }*/
                />
                <button onClick={managementStore.viewMode === 'deleted' ? handleReviveClick : handleDeleteClick}
                    className={styles.deleteButton}>
                    {managementStore?.viewMode === 'deleted' ? 'Восстановить' : 'Удалить'}
                </button>
            </div>


            {/* Полноэкранное фото */}
            {isFullscreen && (
                <div className={styles.fullscreenOverlay} onClick={handleFullscreenToggle}>
                    <img
                        src={product.photoUrls[mainPhotoIndex]}
                        alt="Полноэкранное фото"
                        className={styles.fullscreenPhoto}
                    />
                </div>
            )}
        </div>
    );
});

export default ProductAdditionalInfo;
