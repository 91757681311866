import React, { useState, useEffect, useRef } from 'react';
import styles from './CustomSelect.module.scss';

const CustomSelect = ({
    options = [],
    selectedValues = [],
    onChange,
    mode = 'single', // 'radio', 'single', 'multi'
    placeholder = 'Выберите...',
    label
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(selectedValues);
    const menuRef = useRef(null);

    const toggleOpen = () => setIsOpen((prev) => !prev);

    const handleOptionClick = (optionValue) => {
        let updatedSelection;

        if (mode === 'multi') {
            updatedSelection = selected.includes(optionValue)
                ? selected.filter((value) => value !== optionValue)
                : [...selected, optionValue];
        } else {
            updatedSelection = [optionValue];
            setIsOpen(false); // Close dropdown in single or radio mode
        }

        setSelected(updatedSelection);
        onChange && onChange(updatedSelection);
    };

    const isSelected = (optionValue) => selected.includes(optionValue);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const renderSelectedLabel = () => {
        if (mode === 'multi' && selected.length > 0) {
            return `Выбрано: ${selected.length}`;
        }
        const selectedOption = options.find((opt) => opt.value === selected[0]);
        return selectedOption ? selectedOption.label : placeholder;
    };

    return (
        <div className={styles.container} ref={menuRef}>
            {label && <div className={styles.label}>{label}</div>}
            <div className={styles.selectBox} onClick={toggleOpen}>
                <span className={styles.selectedValue}>{renderSelectedLabel()}</span>
                <div className={styles.arrow}>&#9662;</div>
            </div>
            {isOpen && (
                <div className={styles.dropdown}>
                    {options.map((option) => (
                        <div
                            key={option.value}
                            className={`${styles.option} ${isSelected(option.value) ? styles.selected : ''
                                }`}
                            onClick={() => handleOptionClick(option.value)}
                        >
                            {mode === 'multi' && (
                                <input
                                    type="checkbox"
                                    checked={isSelected(option.value)}
                                    readOnly
                                />
                            )}
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
