import axios from 'axios';
import { api_addr } from './urls';
import TokenManager from '../stores/TokenManager';

const $api = axios.create({
    baseURL: api_addr,
});

const tokenManager = new TokenManager($api);

$api.interceptors.request.use(async (config) => {
    try {
        const tokens = await tokenManager.getTokens();
        if (tokens?.accessToken) {
            config.headers.Authorization = `Bearer ${tokens.accessToken}`;
        }
    } catch (error) {
        console.error('Error attaching Authorization header:', error);
    }
    return config;
});

$api.interceptors.response.use(
    response => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const newTokens = await tokenManager.refreshTokens();
                if (newTokens?.accessToken) {
                    originalRequest.headers.Authorization = `Bearer ${newTokens.accessToken}`;
                    return $api(originalRequest);
                }
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);


                await tokenManager.clearTokens();
                throw refreshError;
            }
        }

        return Promise.reject(error);
    }
);

export default $api;
export { tokenManager };