import React, { useEffect } from 'react';
import ProductManagementControls from '../../Elements/ProductManagementControls/ProductManagementControls';
import ProductManagementTable from '../../Elements/ProductManagementTable/ProductManagementTable';
import ProductAdditionalInfo from '../../Elements/ProductAdditionalInfo/ProductAdditionalInfo';
import Loading from '../../Elements/Loading/Loading';
import { observer } from 'mobx-react-lite';
import managementStore from '../../../stores/managementStore';
import categoriesStore from '../../../stores/categoriesStore';
import styles from './ProductPage.module.scss'
const ProductPage = observer(() => {
    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    managementStore.fetchProducts(),
                    categoriesStore.fetchCategories(),
                ]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    if (managementStore.isLoading || categoriesStore.isLoading) {
        return <Loading />;
    }
    return (
        <div className={styles.productPage}>
            <ProductManagementControls />
            <ProductManagementTable />
            <ProductAdditionalInfo />
        </div>
    );
});

export default ProductPage;