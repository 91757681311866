import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import ColumnManager from '../ColumnManager/ColumnManager'
import CustomSelect from '../CustomSelect/CustomSelect';
import { Save } from 'lucide-react';
import styles from './ProductManagementControls.module.scss'

import managementStore from '../../../stores/managementStore';

const ProductManagementControls = observer(() => {
    const [pageInput, setPageInput] = useState(managementStore.currentPage + 1);

    const handleSearch = (e) => {
        managementStore.setSearchQuery(e.target.value);
    };
    const totalPages = Math.ceil(managementStore.filteredProducts.length / managementStore.pageSize);

    const handlePageInputChange = (e) => {
        const newPage = e.target.value;
        if (/^\d*$/.test(newPage)) { // Разрешаем только числа
            setPageInput(newPage);
        }
    };

    const handlePageInputBlur = () => {
        const page = Math.min(Math.max(Number(pageInput) - 1, 0), totalPages - 1);
        setPageInput(page + 1); // Синхронизируем отображение
        managementStore.currentPage = page;
    };

    const handlePageInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            handlePageInputBlur();
        }
    };

    const handleSaveChanges = async () => {
        await managementStore.saveAllPendingUpdates();
    };

    const viewModeOptions = [
        { value: 'inStock', label: 'В наличии' },
        { value: 'warehouse', label: 'На складе' },
        { value: 'deleted', label: 'Удалены' },
    ];

    const handleViewModeChange = (value) => {
        managementStore.setViewMode(value[0]); // value is an array in CustomSelect
    };

    return (
        <div className={styles.controls}>
            <div className={styles.columnManager}>
                <ColumnManager
                    visibleColumns={managementStore.visibleColumns}
                    setVisibleColumns={(columns) => (managementStore.visibleColumns = columns)}

                />
            </div>
            <div className={styles.pagination}>
                <button
                    className={styles.paginationButton}
                    onClick={() => managementStore.prevPage()}
                    disabled={managementStore.currentPage === 0}
                >
                    Назад
                </button>
                <div className={styles.paginationInfo}>

                    <input
                        type="text"
                        value={pageInput}
                        onChange={handlePageInputChange}
                        onBlur={handlePageInputBlur}
                        onKeyDown={handlePageInputKeyDown}
                        className={styles.pageInput}
                    />{' '}
                    из {totalPages}
                </div>
                <button
                    className={styles.paginationButton}
                    onClick={() => managementStore.nextPage()}
                    disabled={(managementStore.currentPage + 1) * managementStore.pageSize >= managementStore.filteredProducts.length}
                >
                    Вперед
                </button>
            </div>
            <div
                className={styles.saveButton}
                onClick={handleSaveChanges}
                disabled={managementStore.pendingUpdates.size === 0}
            >
                <Save />
            </div>
            <div className={styles.modeFilter}>
                <CustomSelect
                    options={viewModeOptions}
                    selectedValues={[managementStore.viewMode]}
                    onChange={handleViewModeChange}
                    mode="radio"
                    placeholder="Что отображать"
                />
            </div>
            <input
                type="text"
                className={styles.searchInput}
                placeholder="Поиск..."
                onChange={handleSearch}
            />
            <button
                className={styles.addButton}
                onClick={() => managementStore.setAddModalOpen(true)}
            >
                Добавить товар
            </button>
        </div>
    );
});

export default ProductManagementControls