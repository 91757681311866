import infi from '../../../static/images/gifs/time.gif'
import './Loading.scss'
const Loading = () => {
    return (
        <div className='loading'>
            <img src={infi} alt='loading' className='loadingImg' />
        </div>
    );
}

export default Loading