import { url_refresh } from '../http/urls';

class TokenManager {
    constructor(apiInstance) {
        this.api = apiInstance;
        this.accessToken = null;
        this.refreshToken = null;
        this.useCloudStorage = window.Telegram?.WebApp?.initData !== '';
    }

    // Получение значения из Telegram CloudStorage
    async getCloudStorageItem(key) {
        return new Promise((resolve, reject) => {
            window.Telegram.WebApp.CloudStorage.getItem(key, (error, value) => {
                if (error === null) {
                    resolve(value || null);
                } else {
                    console.error(`Ошибка при получении ${key}: ${error}`);
                    reject(error);
                }
            });
        });
    }

    // Сохранение значения в Telegram CloudStorage
    async setCloudStorageItem(key, value) {
        return new Promise((resolve, reject) => {
            window.Telegram.WebApp.CloudStorage.setItem(key, value, (error) => {
                if (error === null) {
                    resolve();
                } else {
                    console.error(`Ошибка при сохранении ${key}: ${error}`);
                    reject(error);
                }
            });
        });
    }

    // Удаление значения из Telegram CloudStorage
    async removeCloudStorageItem(key) {
        return new Promise((resolve, reject) => {
            window.Telegram.WebApp.CloudStorage.removeItem(key, (error) => {
                if (error === null) {
                    resolve();
                } else {
                    console.error(`Ошибка при удалении ${key}: ${error}`);
                    reject(error);
                }
            });
        });
    }

    async setTokens(accessToken, refreshToken) {
        if (!accessToken || !refreshToken) throw new Error('Недопустимые токены');

        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        this.api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

        if (this.useCloudStorage) {
            try {
                await this.setCloudStorageItem('accessToken', accessToken);
                await this.setCloudStorageItem('refreshToken', refreshToken);
            } catch (error) {
                console.error('Ошибка сохранения токенов в CloudStorage:', error);
            }
        } else {
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
        }
    }

    async getTokens() {
        if (this.accessToken && this.refreshToken) {
            return { accessToken: this.accessToken, refreshToken: this.refreshToken };
        }

        if (this.useCloudStorage) {
            try {
                const accessToken = await this.getCloudStorageItem('accessToken');
                const refreshToken = await this.getCloudStorageItem('refreshToken');
                if (accessToken && refreshToken) {
                    this.accessToken = accessToken;
                    this.refreshToken = refreshToken;
                    return { accessToken, refreshToken };
                }
            } catch (error) {
                console.error('Ошибка получения токенов из CloudStorage:', error);
            }
        } else {
            const accessToken = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');
            if (accessToken && refreshToken) {
                this.accessToken = accessToken;
                this.refreshToken = refreshToken;
                return { accessToken, refreshToken };
            }
        }

        return null;
    }

    async refreshTokens() {
        if (!this.refreshToken) throw new Error('Нет refresh токена');

        const response = await this.api.post(url_refresh, {}, {
            headers: { Authorization: `Bearer ${this.refreshToken}` },
        });

        await this.setTokens(response.data.accessToken, response.data.refreshToken);
        return { accessToken: response.data.accessToken, refreshToken: response.data.refreshToken };
    }

    async clearTokens() {
        this.accessToken = null;
        this.refreshToken = null;
        delete this.api.defaults.headers.common['Authorization'];
        if (this.useCloudStorage) {
            try {
                await this.removeCloudStorageItem('accessToken');
                await this.removeCloudStorageItem('refreshToken');
            } catch (error) {
                console.error('Ошибка удаления токенов из CloudStorage:', error);
            }
        } else {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        }
    }
}

export default TokenManager;
