import { makeAutoObservable, runInAction, toJS } from "mobx";
import $api from '../http';
import { getProductsEndpoint } from "../http/endpoints";

const PAGE_SIZE = 10;

class ManagementStore {
    productsInStock = [];
    productsWarehouse = [];
    productsDeleted = [];

    activeProducts = [];
    pendingUpdates = new Map();
    sortField = '';
    sortOrder = '';
    currentPage = 0;
    visibleColumns = [];
    pageSize = PAGE_SIZE;

    filterValues = {
        producerName: new Set(),
        categoryName: new Set(),
        color: new Set(),
        size: new Set(),
        goodCard: new Set()
    };
    activeFilters = {
        producerName: new Set(),
        categoryName: new Set(),
        color: new Set(),
        size: new Set(),
        goodCard: new Set()
    };
    searchQuery = '';
    isLoading = false;
    selectedProduct = null;

    viewMode = 'inStock'; // 'inStock', 'warehouse', 'deleted'

    constructor() {
        makeAutoObservable(this);
    }
    //#region Visible Columns
    initializeVisibleColumns() {
        const columnDefinitions = [
            { id: 0, value: 'article', name: 'Артикул', visible: true },
            { id: 1, value: 'goodCard', name: 'Кар. товара', visible: true },
            { id: 2, value: 'name', name: 'Название', visible: true },
            { id: 3, value: 'amount', name: 'Кол-во', visible: true },
            { id: 4, value: 'price', name: 'Цена', visible: true },
            { id: 5, value: 'categoryName', name: 'Категория', visible: true },
            { id: 6, value: 'producerName', name: 'Производитель', visible: true },
            { id: 7, value: 'color', name: 'Цвет', visible: true },
            { id: 8, value: 'size', name: 'Размер', visible: true },

        ];

        this.visibleColumns = columnDefinitions;
    }
    updateVisibleColumns(columnValue, isVisible) {
        this.visibleColumns = this.visibleColumns.map((col) =>
            col.value === columnValue ? { ...col, visible: isVisible } : col
        );
    }
    //#endregion

    //#region Active Products
    async fetchProducts(mode = 'inStock') {
        this.isLoading = true;
        const { endpoint, params } = getProductsEndpoint();
        try {
            let point = '';
            if (mode === 'inStock') {
                point = '/stock';
            } else if (mode === 'warehouse') {
                point = '/warehouse';
            } else if (mode === 'deleted') {
                point = '/deleted';
            }
            const response = await $api.get(endpoint + point, { params });
            runInAction(() => {
                if (mode === 'inStock') {
                    this.productsInStock = response.data;
                } else if (mode === 'warehouse') {
                    this.productsWarehouse = response.data;
                } else if (mode === 'deleted') {
                    this.productsDeleted = response.data;
                }
                this.updateActiveProducts();
                this.updateFilterValues();
                this.initializeVisibleColumns();
            });
        } catch (error) {
            console.error("Error fetching products:", error);
        } finally {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    syncActiveProductsToCollection() {
        if (this.viewMode === 'inStock') {
            this.productsInStock = [...this.activeProducts];
        } else if (this.viewMode === 'warehouse') {
            this.productsWarehouse = [...this.activeProducts];
        } else if (this.viewMode === 'deleted') {
            this.productsDeleted = [...this.activeProducts];
        }
    }
    updateActiveProducts() {
        if (this.viewMode === 'inStock') {
            this.activeProducts = Array.isArray(this.productsInStock) ? this.productsInStock : [];

        } else if (this.viewMode === 'warehouse') {
            this.activeProducts = Array.isArray(this.productsWarehouse) ? this.productsWarehouse : [];
        } else if (this.viewMode === 'deleted') {
            this.activeProducts = Array.isArray(this.productsDeleted) ? this.productsDeleted : [];
        }
        this.selectedProduct = null;
    }
    setViewMode(mode) {
        if (this.viewMode !== mode) {
            this.syncActiveProductsToCollection();
            this.viewMode = mode;

            if (mode === 'inStock' && this.productsInStock.length === 0) {
                this.fetchProducts('inStock');
            } else if (mode === 'warehouse' && this.productsWarehouse.length === 0) {
                this.fetchProducts('warehouse');
            } else if (mode === 'deleted' && this.productsDeleted.length === 0) {
                this.fetchProducts('deleted');
            } else {
                this.updateActiveProducts();
            }
        }
    }
    //#endregion

    //#region Updating products
    /**
  * Сохраняет значение поля продукта.
  * Проверяет изменения перед отправкой запроса на сервер.
  */
    async saveField(productId, field, value) {
        try {
            const productIndex = this.activeProducts.findIndex((p) => p.id === productId);
            if (productIndex === -1) throw new Error("Product not found");

            // Проверяем, изменилось ли значение
            const currentValue = this.activeProducts[productIndex][field];
            if (currentValue === value) {
                console.log(`No changes for field ${field} of product ${productId}`);
                return;
            }

            // Обновляем локальные данные
            this.activeProducts[productIndex][field] = value;

            // Отправляем изменения на сервер
            await $api.patch(`/admin/products/${productId}`, { [field]: value });
        } catch (error) {
            console.error(`Failed to save field ${field} for product ${productId}`, error);
        }
    }

    /**
   * Устанавливает обновление поля продукта в очередь.
   */
    setPendingUpdate(productId, field, value) {
        if (!this.pendingUpdates.has(productId)) {
            this.pendingUpdates.set(productId, {});
        }
        const updates = this.pendingUpdates.get(productId);

        // Просто добавляем новое значение
        updates[field] = value;
        this.pendingUpdates.set(productId, updates);
    }

    /**
    * Сохраняет все ожидающие обновления для продукта.
    */
    async savePendingUpdates(productId) {
        const updates = this.pendingUpdates.get(productId);
        if (updates && Object.keys(updates).length > 0) {
            try {
                const { endpoint, params } = getProductsEndpoint();
                const response = await $api.patch(`${endpoint}/${productId}`, updates, params);
                if (response.status === 200) {
                    const updatedProduct = response.data;
                    this.activeProducts = this.activeProducts.map((product) =>
                        product.id === productId ? updatedProduct : product
                    );
                    this.pendingUpdates.delete(productId);
                }
            } catch (error) {
                console.error(`Failed to save pending updates for product ${productId}`, error);
            }
        }
    }
    /**
     * Сохраняем все изменения
     */
    async saveAllPendingUpdates() {
        const updates = Array.from(this.pendingUpdates.entries());
        if (updates.length === 0) {
            console.log("Нет изменений для сохранения.");
            return;
        }
        // Формируем массив объектов для серверного запроса
        const updateData = updates.map(([productId, fields]) => ({
            id: productId,
            ...fields,
        }));
        try {
            const { endpoint, params } = getProductsEndpoint();

            const response = await $api.patch(`${endpoint}/batch`, updateData, { params });
            if (response.status === 200) {
                console.log("Все изменения успешно сохранены.");

                runInAction(() => {
                    updates.forEach(([productId, fields]) => {
                        const index = this.activeProducts.findIndex((product) => product.id === productId);
                        if (index !== -1) {
                            this.activeProducts[index] = { ...this.activeProducts[index], ...fields };
                        }
                    });

                    // Удаляем успешно отправленные изменения
                    this.pendingUpdates.clear();
                });
            } else {
                console.warn("Некоторые изменения могли не сохраниться. Ответ сервера:", response.data);
            }
        } catch (error) {
            console.error("Ошибка при сохранении изменений:", error);
        }
    }
    /**
   * Обновляет локальное значение поля продукта.
   */
    updateProductField(productId, field, value) {
        try {
            const productIndex = this.activeProducts.findIndex((p) => p.id === productId);
            if (productIndex === -1) throw new Error("Продукт не найден");

            const currentValue = this.activeProducts[productIndex][field];
            if (currentValue === value) {
                console.log(`Значение поля ${field} не изменилось для продукта ${productId}`);
                return;
            }

            this.activeProducts[productIndex][field] = value;
            this.setPendingUpdate(productId, field, value); // Устанавливаем изменение
        } catch (error) {
            console.error(`Ошибка обновления поля: ${error}`);
        }
    }
    setSelectedProduct(product) {
        this.selectedProduct = product;
    }

    clearSelectedProduct() {
        this.selectedProduct = null;
    }
    //#endregion

    //#region Sorting fields and pagination
    sortProducts(field) {
        if (this.sortField === field) {
            this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
            this.sortField = field;
            this.sortOrder = 'asc';
        }
        this.products = this.products.slice().sort((a, b) => {
            const aValue = a[field];
            const bValue = b[field];
            return this.sortOrder === 'asc'
                ? (aValue > bValue ? 1 : aValue < bValue ? -1 : 0)
                : (aValue < bValue ? 1 : aValue > bValue ? -1 : 0);
        });
    }
    get sortedProducts() {
        return toJS(this.products);
    }

    nextPage() {
        if ((this.currentPage + 1) * PAGE_SIZE < this.filteredProducts.length) {
            this.currentPage += 1;
        }
    }

    prevPage() {
        if (this.currentPage > 0) {
            this.currentPage -= 1;
        }
    }

    /**
  * Продукты для отображения на текущей странице.
  */
    get paginatedProducts() {
        const start = this.currentPage * PAGE_SIZE;
        const end = start + PAGE_SIZE;
        return this.filteredProducts.slice(start, end);
    }
    //#endregion

    //#region Filtering and Search
    setFilterValues() {
        this.filterValues.producerName = new Set(
            [...this.activeProducts
                .map(product => product.producerName)
                .filter(Boolean)
                .sort((a, b) => a.localeCompare(b)),
                ''] // Добавляем пустую строку в конце
        );
        this.filterValues.color = new Set(
            [...this.activeProducts
                .map(product => product.color)
                .filter(Boolean)
                .sort((a, b) => a.localeCompare(b)),
                '']
        );
        this.filterValues.size = new Set(
            [...this.activeProducts
                .map(product => product.size)
                .filter(Boolean)
                .sort((a, b) => a.localeCompare(b)),
                '']
        );
        this.filterValues.goodCard = new Set(
            [...this.activeProducts
                .map(product => product.goodCard)
                .filter(Boolean)
                .sort((a, b) => a.localeCompare(b)),
                '']
        );
    }

    /**
  * Устанавливаем фильтр для поля.
  */
    setFilter(field, values) {
        this.activeFilters[field] = new Set(values);
        this.currentPage = 0; // Сбрасываем текущую страницу после фильтрации
    }

    updateFilterValues = () => {
        this.activeProducts.forEach(product => {
            ['producerName', 'color', 'size', 'goodCard', 'categoryName'].forEach(field => {
                if (product[field]) {
                    this.filterValues[field].add(product[field]);
                }
            });
        });
    };

    setSearchQuery(query) {
        this.searchQuery = query.toLowerCase();
    };

    get filteredProducts() {
        return this.activeProducts.filter((product) => {
            // Apply filters
            for (const [field, activeValues] of Object.entries(this.activeFilters)) {
                if (activeValues.size > 0) {
                    const productValue = product[field] || '';
                    if (!activeValues.has(productValue)) {
                        return false;
                    }
                }
            }

            // Apply search query
            if (this.searchQuery) {
                const query = this.searchQuery.toLowerCase();
                return Object.values(product).some((value) =>
                    String(value).toLowerCase().includes(query)
                );
            }

            return true;
        });
    }
    get searchedProducts() {
        const query = this.searchQuery.toLowerCase();
        return this.filteredProducts.filter(product => {
            return Object.values(product).some(value =>
                String(value).toLowerCase().includes(query)
            );
        });
    }
    //#endregion

    //#region Add and Delete
    addProduct = async (productData) => {
        try {
            const { endpoint, params } = getProductsEndpoint();
            const response = await $api.post(endpoint, productData, { params });
            runInAction(() => {
                this.products.push(response.data);
                this.updateFilterValues();
            });
            return response.data;
        } catch (error) {
            console.error('Error adding product:', error);
            throw error;
        }
    };

    async deleteProduct(productId) {
        try {
            const { endpoint, params } = getProductsEndpoint();
            await $api.delete(`${endpoint}/${productId}`, { params });
            runInAction(() => {
                this.activeProducts = this.activeProducts.filter(p => p.id !== productId);
                this.updateFilterValues();
            });
        } catch (error) {
            console.error('Error deleting product:', error);
            throw error;
        }
    };
    async reviveProduct(productId) {
        try {
            const { endpoint, params } = getProductsEndpoint();
            const response = await $api.patch(`${endpoint}/revive/${productId}`, null, { params });
            if (response.status === 204) {
                runInAction(() => {
                    // Удаляем товар из activeProducts
                    this.activeProducts = this.activeProducts.filter(p => p.id !== productId);

                    // Добавляем товар в warehouse
                    const revivedProduct = this.productsDeleted.find(p => p.id === productId);
                    if (revivedProduct) {
                        this.productsWarehouse.push(revivedProduct);
                        this.productsDeleted = this.productsDeleted.filter(p => p.id !== productId);
                    }
                });
            }
        } catch (error) {
            console.error(`Ошибка при восстановлении продукта ${productId}:`, error);
        }
    }
    //#endregion
}

const managementStore = new ManagementStore();
export default managementStore;
