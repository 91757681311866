import { makeAutoObservable } from "mobx";
import { tokenManager } from "../http";
import $api from "../http";
import { url_authenticate, url_set_creds, url_get_role } from '../http/urls';

class UserStore {
  user = null; // Данные пользователя
  role = ""; // Роль пользователя
  isLoading = true; // Флаг загрузки
  isMiniApp = window.Telegram?.WebApp?.initData !== ''; // Проверка, запущено ли приложение в MiniApp
  tokenManager = tokenManager; // Менеджер токенов

  constructor() {
    makeAutoObservable(this);
    this.initialize();
  }

  async initialize() {
    try {
      const tokens = await this.tokenManager.getTokens();
      if (tokens?.accessToken) {
        await this.fetchUserRole(); // Проверяем роль, если токены валидны
      }
    } catch (error) {
      console.error('Ошибка инициализации:', error);
    } finally {
      this.isLoading = false;
    }
  }

  //#region Методы авторизации

  /**
   * Авторизация с использованием initData (Telegram MiniApp)
   * @param {string|null} login - Логин пользователя
   * @param {string|null} password - Пароль пользователя
   */
  async authenticateWithInitData(login = null, password = null) {
    try {
      const initData = this.isMiniApp ? window.Telegram.WebApp.initData : null;
      this.isLoading = true;
      const response = await $api.post(url_authenticate, {
        InitData: initData,
        Login: login,
        Password: password,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data.success) {
        const user = this.isMiniApp
          ? window.Telegram.WebApp.initDataUnsafe?.user
          : { id: response.data.id };

        await this.tokenManager.setTokens(response.data.accessToken, response.data.refreshToken); // Сохраняем токены
        this.user = user;
        await this.fetchUserRole(); // Получаем роль после успешной авторизации
      } else {
        throw new Error('Ошибка аутентификации');
      }

      return response.data;
    } catch (error) {
      console.error('Ошибка аутентификации:', error);
      await this.tokenManager.clearTokens();
      this.clearUser();
      throw error;
    }
    finally {
      this.isLoading = false;
    }
  }

  /**
   * Установка учетных данных (логина и пароля)
   * @param {string} login - Логин пользователя
   * @param {string} password - Пароль пользователя
   */
  async setCredentials(login, password) {
    try {
      const initData = this.isMiniApp ? window.Telegram.WebApp.initData : null;

      const response = await $api.post(url_set_creds, {
        initData,
        login,
        password,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data.success) {
        const user = this.isMiniApp
          ? window.Telegram.WebApp.initDataUnsafe?.user
          : { id: response.data.id };

        await this.tokenManager.setTokens(response.data.accessToken, response.data.refreshToken); // Сохраняем токены
        this.user = user;
        await this.fetchUserRole(); // Обновляем роль
      } else {
        throw new Error('Ошибка установки учетных данных');
      }

      return response.data;
    } catch (error) {
      console.error('Ошибка установки учетных данных:', error);
      throw error;
    }
  }

  //#endregion

  /**
   * Получение роли пользователя
   */
  async fetchUserRole() {
    try {
      const response = await $api.get(url_get_role);
      this.role = response.data.role;
    } catch (error) {
      console.error('Ошибка получения роли пользователя:', error);
      this.clearUser();
    }
  }

  /**
   * Очистка данных пользователя
   */
  clearUser() {
    this.user = null;
    this.role = "";
  }

  /**
   * Выход из учетной записи
   */
  logout() {
    this.clearUser();
    this.tokenManager.clearTokens();
  }

  /**
   * Проверка, авторизован ли пользователь
   */
  /**
   * Проверка, авторизован ли пользователь
   */
  get isAuthenticated() {
    // Пользователь авторизован, если в хранилище есть токен и данные пользователя
    return !!this.user && !!this.tokenManager.accessToken;
  }
}
const userStore = new UserStore();
export default userStore;
