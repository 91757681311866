import React from 'react';
import { observer } from 'mobx-react-lite';
import CustomSelect from '../../Elements/CustomSelect/CustomSelect';
import styles from './ColumnManager.module.scss';

const ColumnManager = observer(({ visibleColumns, setVisibleColumns }) => {

    if (!visibleColumns || visibleColumns.length === 0) {
        return <div className={styles.noColumns}>Нет доступных столбцов для управления</div>;
    }

    return (
        <div className={styles.columnManager}>
            <CustomSelect
                options={visibleColumns.map((col) => ({
                    value: col.value,
                    label: col.name,
                }))}
                selectedValues={visibleColumns.filter((col) => col.visible).map((col) => col.value)}
                onChange={(selected) => {
                    const updatedColumns = visibleColumns.map((col) => ({
                        ...col,
                        visible: selected.includes(col.value),
                    }));
                    setVisibleColumns(updatedColumns);
                }}
                mode="multi"
                placeholder="Управление столбцами"
            />
        </div>
    );
});
export default ColumnManager;