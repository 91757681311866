import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import userStore from './stores/userStore';
import Login from './components/Pages/Login/Login';
import EmptyScreen from './components/Pages/EmptyScreen/EmptyScreen';
//import Dashboard from './components/Pages/Dashboard/Dashboard';
import Statistics from './components/Pages/Statistics/Statistics';
import Management from './components/Pages/Management/Management';
import Orders from './components/Pages/Orders/Orders';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import SetCredentials from './components/Pages/SetCredentials/SetCredentials'; import './App.scss';
import ProductPage from './components/Pages/ProductPage/ProductPage';
import ManagersControlTable from './components/Pages/ManagersControlTable/ManagersControlTable';
import SideMenu from './components/Elements/SideMenu/SideMenu';
import Loading from './components/Elements/Loading/Loading';

const App = observer(() => {
    const appRef = useRef(null);

    useEffect(() => {
        if (userStore.isMiniApp) {
            // Выполняем аутентификацию при открытии в MiniApp
            userStore.authenticateWithInitData().catch(error => {
                console.error('Ошибка аутентификации в MiniApp:', error);
            });
        }
    }, []);

    if (userStore.isLoading) {
        return <Loading />;
    }

    return (
        <div ref={appRef} className="App">
            <Router>
                {userStore.isAuthenticated && <SideMenu />}
                <Routes>
                    <Route path="/cdgfhntqkTKSnCanSfwRqwc" element={<Login />} />
                    <Route path="/cVRp6RRbkTKSnCanSfwR4QM" element={<PrivateRoute><SetCredentials /></PrivateRoute>} />
                    <Route path="/dashboard" element={<PrivateRoute><EmptyScreen /></PrivateRoute>} />
                    <Route path="/CdDCwZhQdzVOB6Ex01kuwQO" element={<PrivateRoute><Statistics /></PrivateRoute>} />
                    <Route path="/uROnGCSdu5hzy1mpRPMnneE" element={<PrivateRoute><Management appRef={appRef} /></PrivateRoute>} />
                    <Route path="/6vkD9rk8smiHfVP37J2nwhr" element={<PrivateRoute><Orders /></PrivateRoute>} />
                    <Route path="/ur3fTPWfv0MHfA4vDOYzKCw" element={<PrivateRoute><ProductPage /></PrivateRoute>} />
                    {/*<Route path="/nKVa5NYe4FMWxlCo0jyBzk7fTZ" element={<PrivateRoute><ManagersControlTable /></PrivateRoute>} />*/}
                    <Route path="/" element={<Navigate to={userStore.isAuthenticated ? "/dashboard" : "/cdgfhntqkTKSnCanSfwRqwc"} replace />} />
                </Routes>
            </Router>
        </div>
    );
});

export default App;