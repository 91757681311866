import React, { useState, useEffect, useCallback, useRef } from 'react';
import styles from './Input.module.scss';

const Input = ({
  value,
  onChange,
  onBlur,
  readOnly,
  label,
  suggestions = [],
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState(value);
  const suggestionsRef = useRef(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (inputValue && isFocused) {
      const filtered = suggestions.filter((item) =>
        item.toLowerCase().startsWith(inputValue.toLowerCase())
      );

      if (
        filtered.length !== filteredSuggestions.length || // Избегаем дублирующего обновления
        filtered.some((item, idx) => item !== filteredSuggestions[idx])
      ) {
        setFilteredSuggestions(filtered);
      }
    } else if (filteredSuggestions.length > 0) {
      setFilteredSuggestions([]);
    }
  }, [inputValue, isFocused, suggestions, filteredSuggestions]);

  const handleInputChange = useCallback(
    (e) => {
      const newValue = e.target.value;
      setInputValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    },
    [onChange]
  );

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setFilteredSuggestions([]);
    if (onChange) {
      onChange(suggestion);
    }
  };

  const handleBlur = (e) => {
    setTimeout(() => setIsFocused(false), 200); // Timeout to allow click on suggestion
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <div className={styles.inputContainer}>
      {label && <label className={styles.inputLabel}>{label}</label>}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsFocused(true)}
        onBlur={handleBlur}
        readOnly={readOnly}
        className={`${styles.inputField} ${readOnly ? styles.inputReadonly : ''}`}
      />
      {isFocused && filteredSuggestions.length > 0 && (
        <ul className={styles.suggestions} ref={suggestionsRef}>
          {filteredSuggestions.map((suggestion, index) => (
            <li
              key={index}
              className={styles.suggestion}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Input;
