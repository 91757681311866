import React from 'react';
import './ErrorPage.scss';

const ErrorPage = ({ message }) => {
    return (
        <div className="error-page">
            <div className="sticker">
                {/* Можно заменить SVG на анимированное изображение или GIF */}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="96"
                    height="96"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="sticker-icon"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.75 9l-6.52 6.52A3.25 3.25 0 003.25 18h0A3.25 3.25 0 006.52 9.75L12 4.25a3.25 3.25 0 014.96 0h0A3.25 3.25 0 0118 6.52L12.5 12m0 0l6.5 6.5M12.5 12L9 15m3.5-3l3-3" />
                </svg>
            </div>
            <h1 className="error-message">{message || 'У вас нет доступа'}</h1>
        </div>
    );
};

export default ErrorPage;
