import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Menu, UserRoundCog, BarChart2, Package, ShoppingCart, Settings } from 'lucide-react';
import userStore from '../../../stores/userStore';
import './SideMenu.scss';

const SideMenu = observer(() => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuExpanded, setMenuExpanded] = useState(true);
    const [tooltip, setTooltip] = useState(null);

    const handleMenuItemClick = () => {
        setMenuVisible(false);
        setMenuExpanded(false);
    };
    const handleMouseEnter = () => {
        if (!menuVisible) {
            setMenuExpanded(true);
        }
    };

    const handleMouseLeave = () => {
        if (!menuVisible) {
            setMenuExpanded(false);
        }
    };
    const handleItemMouseEnter = (message) => {
        if (message) {
            setTooltip(message);
        }
    };

    const handleItemMouseLeave = () => {
        setTooltip(null);
    };
    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
        setMenuExpanded(!menuExpanded);
    };

    const renderMenuItems = () => {
        if (userStore.isMiniApp) {
            // Если приложение открыто в MiniApp, отображаем только SetCredentials
            return (
                <Link to="/cVRp6RRbkTKSnCanSfwR4QM"
                    className="tileItem"
                    onClick={handleMenuItemClick}>
                    <Settings /> <span>Установить логин и пароль</span>
                </Link>
            );
        }
        return (
            <>
                {userStore.role === "Admin" && (
                    <Link to="/nKVa5NYe4FMWxlCo0jyBzk7fTZ"
                        className="tileItem"
                        onClick={handleMenuItemClick}>
                        <UserRoundCog /> <span>Менеджеры и Админы</span>
                    </Link>
                )}
                {/* Кнопка Статистика (отключена) */}
                <div to="/CdDCwZhQdzVOB6Ex01kuwQO"
                    className="tileItem disabled"
                    //onClick={handleMenuItemClick}
                    onMouseEnter={() => handleItemMouseEnter("Будет готово чуть позже")}
                    onMouseLeave={handleItemMouseLeave}
                >
                    <BarChart2 /> <span>Статистика</span>
                </div>
                {/* Кнопка Товары */}
                <Link
                    to={userStore.isMiniApp ? "/uROnGCSdu5hzy1mpRPMnneE" : "/ur3fTPWfv0MHfA4vDOYzKCw"}
                    className="tileItem"
                    onClick={handleMenuItemClick}
                >
                    <Package /> <span>Товары</span>
                </Link>
                {/* Кнопка Заказы (отключена) */}
                <div to="/6vkD9rk8smiHfVP37J2nwhr"
                    className="tileItem disabled"
                    //onClick={handleMenuItemClick}
                    onMouseEnter={() => handleItemMouseEnter("Будет готово чуть позже")}
                    onMouseLeave={handleItemMouseLeave}
                >
                    <ShoppingCart /> <span>Заказы</span>
                </div>
                <Link to="/cVRp6RRbkTKSnCanSfwR4QM"
                    className="tileItem"
                    onClick={handleMenuItemClick}>
                    <Settings /> <span>Установить логин и пароль</span>
                </Link>
            </>
        );
    };

    return (
        <>
            {userStore.isAuthenticated && (
                <div
                    className={`side-menu ${menuVisible ? 'menu-expanded' : menuExpanded ? 'menu-expanded' : 'menu-collapsed'}`}
                    onMouseLeave={handleMouseLeave}
                >
                    {!menuVisible && (
                        <div className="menu-items" onClick={toggleMenu} onMouseEnter={handleMouseEnter}>
                            <Menu color="white" size={24} />
                        </div>
                    )}
                    <div className="menu-items">{renderMenuItems()}</div>
                </div>
            )}
        </>
    );
});

export default SideMenu;
