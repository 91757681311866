import { makeAutoObservable, runInAction } from "mobx";
import $api from '../http';
import { getCategoriesEndpoint } from "../http/endpoints";
class CategoriesStore {
    categories = [];

    constructor() {
        makeAutoObservable(this);
    }

    async fetchCategories() {
        try {
            const { endpoint, params } = getCategoriesEndpoint();
            const response = await $api.get(endpoint, { params });
            runInAction(() => {
                this.categories = response.data;
            });
            return this.categories;
        } catch (error) {
            console.error('Failed to fetch categories', error);
        }
    }
}

export default new CategoriesStore();
