import React from 'react';
import { Navigate } from 'react-router-dom';
import userStore from '../../stores/userStore';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';

const PrivateRoute = ({ children }) => {
    if (userStore.isMiniApp) {
        // Если приложение работает в MiniApp
        if (userStore.isAuthenticated) {
            // Если пользователь авторизован, проверяем маршрут
            const allowedRoutes = ['/cVRp6RRbkTKSnCanSfwR4QM', '/dashboard']; // Разрешенные маршруты
            const currentPath = window.location.pathname;

            if (allowedRoutes.includes(currentPath)) {
                // Если маршрут разрешен, показываем содержимое
                return children;
            } else {
                // Если маршрут не разрешен, перенаправляем на SetCredentials
                return <Navigate to="/cVRp6RRbkTKSnCanSfwR4QM" replace />;
            }
        } else {
            // Если пользователь не авторизован, показываем страницу ошибки
            return <ErrorPage message="Для работы в MiniApp необходимо пройти аутентификацию" />;
        }
    }

    return userStore.isAuthenticated ? children : <Navigate to="/cdgfhntqkTKSnCanSfwRqwc" replace />;
};

export default PrivateRoute;